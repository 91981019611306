/**  水印添加方法  */
//str 水印文字
let setWatermark = str => {
  let id = "watermark_box";

  if (document.getElementById(id) !== null) {
    document.body.removeChild(document.getElementById(id));
  }

  let can = document.createElement("canvas");
  // 设置canvas画布大小
  can.width = 380;
  can.height = 120;

  let cans = can.getContext("2d");
  cans.rotate((-20 * Math.PI) / 180); // 水印旋转角度
  cans.font = "16px 微软雅黑";
  cans.fillStyle = "#000";
  cans.textAlign = "center";
  cans.textBaseline = "Middle";
  //fillText("要现实的文字",x,y)，这里面的x,y代表的是哪个点的坐标
  cans.fillText(str, can.width / 2, can.height, can.width); // 水印在画布的位置x，y轴

  let div = document.createElement("div");
  div.id = id;
  //pointerEvents此CSS属性设置为“none”时，元素不接收悬停/单击事件，而事件将发生在其后面的任何内容上。
  div.style.pointerEvents = "none";
  div.style.top = "60px";
  div.style.left = "50px";
  div.style.opacity = "0.1";
  div.style.position = "fixed";
  div.style.zIndex = "100000";
  div.style.width = document.documentElement.clientWidth + "px";
  div.style.height = document.documentElement.clientHeight + "px";
  div.style.background =
    "url(" + can.toDataURL("image/png") + ") left top repeat";
  document.body.appendChild(div);
  return id;
};

// 添加水印方法
export const setWaterMark = str => {
  let id = setWatermark(str);
  if (document.getElementById(id) === null) {
    id = setWatermark(str1);
  }
};

// 移除水印方法
export const removeWatermark = () => {
  let id = "watermark_box";
  if (document.getElementById(id) !== null) {
    document.body.removeChild(document.getElementById(id));
  }
};
