import Vue from 'vue'
import Vuex from 'vuex'
import util from '@/common/utils/util.js'
import VueRouter from 'vue-router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menus: [],
    tabs:[],
    openedViews:[],
    cachedViews:["home"],
    tabsValue: 'a?a=n',
    defaultActive: '',
  },
  mutations: {
    setMenus(state, m){
      if(!util.isEmpty(m)){
        state.menus = m;
      } else {
        state.menus = []
      }
    },
    addTab(state, tab) {
      for(let i=0;i<state.tabs.length;i++){
        if(state.tabs[i].name == tab.name){
          return
        }
      }
      state.tabs.push(tab);
    },
    setTabsValue(state, val) {
      state.tabsValue = val;
    },
    setDefaultActive(state, val) {
      state.defaultActive = val;
    },
    removeTab(state,param){
      let opt = param.opt;
      let router = param.router;
      console.log(Vue.router)
      for (let i=(state.tabs.length-1);i>=0;i--){
        if(state.tabs[i].name == opt){
          if(state.tabsValue == state.tabs[i].name){
            // router.replace(state.tabs[i-1].name);
            router(state.tabs[i-1].name)
            state.tabsValue = state.tabs[i-1].name;
            state.defaultActive = state.tabs[i-1].name;
          }
          state.cachedViews.splice(i,1)
          state.tabs.splice(i,1)

          break;
        }
      }
    },
    openTab(state,param){
      let item = param.item;
      let router = param.router;

      for (let i=0;i<state.tabs.length;i++){
        if(state.tabs[i].name == item.path){
          router(item.path);
          state.tabsValue = item.path;
          return
        }
      }

      state.tabs.push({
        name: item.path,
        title: item.title,
        icon: item.icon,
        componentName: item.componentName,
        closable: true,
      })
      state.cachedViews.push(item.componentName)
      state.tabsValue = item.path;

      router(item.path);
    },
    logout(state){
      state.menus = [];
      state.tabs=[];
      state.openedViews=[];
      state.cachedViews=["home"];
      state.tabsValue='';
      state.defaultActive='';
    }



  },
  actions: {
  },
  modules: {
  }
})
