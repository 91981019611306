import Vue from "vue";
import VueRouter from "vue-router";

import NProgress from "nprogress";
import "nprogress/nprogress.css";

import { getToken } from "@/common/utils/auth.js";
import util from "@/common/utils/util.js";
import { setWaterMark } from "../canvs/waterMars";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    // name: 'index',
    redirect: "/index"
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login"),
    meta: {
      title: "用户登录"
    }
  },
  {
    path: "/index",
    name: "index",
    component: () => import("../views/layout/tabs"),
    meta: {
      title: "首页"
    },
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("../views/home"),
        meta: {
          title: "首页"
        }
      },
      {
        path: "/system/user_manage",
        name: "userManage",
        component: () => import("../views/system/user_manage/user_manage"),
        meta: {
          title: "用户管理"
        }
      },
      {
        path: "/system/role_manage",
        name: "roleManage",
        component: () => import("../views/system/role_manage/role_manage"),
        meta: {
          title: "角色管理"
        }
      },
      {
        path: "/system/resource_manage",
        name: "resourceManage",
        component: () =>
          import("../views/system/resource_manage/resource_manage"),
        meta: {
          title: "资源管理"
        }
      },

      {
        path: "/participate/participate_manage",
        name: "participateManage",
        component: () =>
          import("../views/participate/participate_manage/participate_manage"),
        meta: {
          title: "参会人员管理"
        }
      },
      {
        path: "/system/yy_user_manage",
        name: "yyUserManage",
        component: () =>
          import("../views/system/yy_user_manage/yy_user_manage"),
        meta: {
          title: "邀宾用户管理"
        }
      },
      {
        path: "/participate/my_participate_manage",
        name: "participateManage",
        component: () =>
          import(
            "../views/participate/my_participate_manage/my_participate_manage"
          ),
        meta: {
          title: "嘉宾管理"
        }
      },
      {
        path: "/participate/my_participate_manageyincang",
        name: "my_participate_manageyincang",
        component: () =>
          import(
            "../views/participate/my_participate_manage/my_participate_manageyincang"
          ),
        meta: {
          title: "我邀请的嘉宾隐藏页"
        }
      },
      {
        path: "/plate/plate_manage",
        name: "plateManage",
        component: () => import("../views/plate/plate_manage/plate_manage"),
        meta: {
          title: "会议管理"
        }
      },
      {
        path: "/cms/article_manage",
        name: "plateManage",
        component: () => import("../views/cms/article_manage/article_manage"),
        meta: {
          title: "文章管理"
        }
      },
      {
        path: "/participate/all_participate_manage",
        name: "participateManage",
        component: () =>
          import(
            "../views/participate/all_participate_manage/all_participate_manage"
          ),
        meta: {
          title: "全部嘉宾管理"
        }
      },
      {
        path: "/participate/aparticipateapp",
        name: "aparticipateapp",
        component: () => import("../views/participate/apppeople"),
        meta: {
          title: "小程序嘉宾管理"
        }
      },
      {
        path: "/participate/cpeople",
        name: "cpeople",
        component: () => import("../views/participate/appcpeopele"),
        meta: {
          title: "小程序用户管理"
        }
      },
      {
        path: "/participate/participate_logs",
        name: "participateLogs",
        component: () =>
          import("../views/participate/participate_logs/participate_logs"),
        meta: {
          title: "修改记录"
        }
      },
      {
        path: "/participate/participate_manage2/:utid",
        name: "participateManage2",
        component: () =>
          import(
            "../views/participate/participate_manage2/participate_manage2"
          ),
        meta: {
          title: "嘉宾管理"
        }
      },
      {
        path: "/participate/participate_manage_status",
        name: "participateManageStatus",
        component: () =>
          import(
            "../views/participate/participate_manage_status/participate_manage_status"
          ),
        meta: {
          title: "回收站"
        }
      },
      {
        path: "/participate/participate_manage_dl",
        name: "participateManageDl",
        component: () =>
          import(
            "../views/participate/participate_manage_dl/participate_manage_dl"
          ),
        meta: {
          title: "修改抵离信息"
        }
      },
      {
        path: "/scan_log/scan_log",
        name: "scanLog",
        component: () => import("../views/scan_log/scan_log"),
        meta: {
          title: "会议扫码时间"
        }
      },
      {
        path: "/plate/visit_site_manage",
        name: "visitSiteManage",
        component: () =>
          import("../views/plate/visit_site_manage/visit_site_manage"),
        meta: {
          title: "展览展映管理"
        }
      },
      {
        path: "/system/user_login_log",
        name: "userLoginLog",
        component: () =>
          import("../views/system/user_login_log/user_login_log"),
        meta: {
          title: "用户登录日志"
        }
      },
      {
        path: "/device/device_manage",
        name: "deviceManage",
        component: () => import("../views/device/device_manage/device_manage"),
        meta: {
          title: "设备管理"
        }
      },
      {
        path: "/device/device_user_manage/:deviceId",
        name: "deviceUserManage",
        component: () =>
          import("../views/device/device_user_manage/device_user_manage"),
        meta: {
          title: "人员管理"
        }
      },
      {
        path: "/device/device_user_log",
        name: "deviceUserLog",
        component: () =>
          import("../views/device/device_user_log/device_user_log"),
        meta: {
          title: "通行记录"
        }
      },
      {
        path: "/device/yycompany_participate_manage/:yyCompanyId",
        name: "yycompanyParticipateManage",
        component: () =>
          import(
            "../views/participate/yycompany_participate_manage/yycompany_participate_manage"
          ),
        meta: {
          title: "邀约单位邀宾管理"
        }
      },
      {
        path: "/participate/all_participate_manage_view",
        name: "participateManage",
        component: () =>
          import(
            "../views/participate/all_participate_manage_view/all_participate_manage_view"
          ),
        meta: {
          title: "全部嘉宾查看"
        }
      },
      {
        path: "/participate/all_participate_manage_hide",
        name: "allParticipateManageHide",
        component: () =>
          import(
            "../views/participate/all_participate_manage_hide/all_participate_manage_hide"
          ),
        meta: {
          title: "其他嘉宾管理(管理员)"
        }
      },
      {
        path: "/participate/my_participate_manage_hide",
        name: "allParticipateManageHide",
        component: () =>
          import(
            "../views/participate/my_participate_manage_hide/my_participate_manage_hide"
          ),
        meta: {
          title: "其他嘉宾管理"
        }
      },
      {
        path: "/participate/participate_manage_status_hide",
        name: "participateManageStatusHide",
        component: () =>
          import(
            "../views/participate/participate_manage_status_hide/participate_manage_status_hide"
          ),
        meta: {
          title: "其他嘉宾管理"
        }
      },
      {
        path: "/participate/my_participate_manage_hide2",
        name: "allParticipateManageHide2",
        component: () =>
          import(
            "../views/participate/my_participate_manage_hide2/my_participate_manage_hide2"
          ),
        meta: {
          title: "其他嘉宾管理2"
        }
      },
      {
        path: "/participate/my_participate_manage2",
        name: "participateManage",
        component: () =>
          import(
            "../views/participate/my_participate_manage2/my_participate_manage2"
          ),
        meta: {
          title: "我邀请的嘉宾2"
        }
      },
      {
        path: "/participate/participate_manage_dl_hide",
        name: "participateManageDl",
        component: () =>
          import(
            "../views/participate/participate_manage_dl_hide/participate_manage_dl_hide"
          ),
        meta: {
          title: "修改抵离信息(其他)"
        }
      },
      {
        path: "/plate/visit_order_manage",
        name: "visitOrderManage",
        component: () =>
          import("../views/plate/visit_order_manage/visit_order_manage"),
        meta: {
          title: "展映展览报名(核销)信息"
        }
      },
      {
        path: "/participate/participate_manage_bind_card",
        name: "participateManageBindCard",
        component: () =>
          import(
            "../views/participate/participate_manage_bind_card/participate_manage_bind_card"
          ),
        meta: {
          title: "参会人员管理_绑卡"
        }
      },
      {
        path: "/yq_one",
        name: "yqOne",
        component: () => import("../views/yq_one/yq_one"),
        meta: {
          title: "每日体温上报管理"
        }
      },
      {
        path: "/participate/participate_manage_bind_card_hide",
        name: "participateManageBindCardHide",
        component: () =>
          import(
            "../views/participate/participate_manage_bind_card_hide/participate_manage_bind_card_hide"
          ),
        meta: {
          title: "参会人员管理_绑卡"
        }
      },
      {
        path: "/task/taskguanList",
        name: "taskguanli",
        component: () => import("../views/task/taskList"),
        meta: {
          title: "任务列表"
        }
      },
      // {
      //   path: '/yiqing/yiqingList',
      //   name: 'yiqingguanli',
      //   component: () => import('../views/yiqing/yiqinggl'),
      //   meta: {
      //     title: '疫情管理'
      //   }
      // },
      {
        path: "/situation/situationBook",
        name: "situationBook",
        component: () => import("../views/situation/situationBook"),
        meta: {
          title: "疫情承诺书管理"
        }
      },
      {
        path: "/bumen/bumen",
        name: "bumenList",
        component: () => import("../views/Department/list"),
        meta: {
          title: "部门管理"
        }
      },
      {
        path: "/qixiajiabin/qixiajiabin",
        name: "qixiajiabinList",
        component: () => import("../views/guanliyuanqx/list"),
        meta: {
          title: "旗下嘉宾管理"
        }
      },
      {
        path: "/exceldao/exceldao",
        name: "exceldaoru",
        component: () => import("../views/ExcelImport/daoru"),
        meta: {
          title: "Excel导入管理"
        }
      },
      {
        path: "/excelworkdao/excelworkdao",
        name: "excelworkdaoru",
        component: () => import("../views/ExcelImport/gongzuodaoru"),
        meta: {
          title: "Excel工作人员导入管理"
        }
      },
      {
        path: "/help/helpList",
        name: "helpList",
        component: () => import("../views/help/helpList"),
        meta: {
          title: "帮助中心"
        }
      },
      {
        path: "/Applets/AppletsList",
        name: "AppletsList",
        component: () => import("../views/Applets/list"),
        meta: {
          title: "小程序轮播图管理"
        }
      },
      {
        path: "/lunbotu/lunbotuList",
        name: "lunbotuList",
        component: () => import("../views/lunbotu/lunbotu"),
        meta: {
          title: "小程序轮播图管理"
        }
      },
      {
        path: "/unit/unitList",
        name: "unitList",
        component: () => import("../views/unit/list"),
        meta: {
          title: "科影未来单元"
        }
      },
      {
        path: "/unit/shijiedanyuanList",
        name: "shijiedanyuanList",
        component: () => import("../views/unit/shijiedanyuanList"),
        meta: {
          title: "世界电影之都"
        }
      },
      {
        path: "/unit/jinyaoshiList",
        name: "jinyaoshiList",
        component: () => import("../views/unit/jinyaoshiList"),
        meta: {
          title: "金钥匙"
        }
      },
      {
        path: "/unit/haipingmianList",
        name: "haipingmianList",
        component: () => import("../views/unit/haipingmianList"),
        meta: {
          title: "海平面"
        }
      },
      {
        path: "/unit/jinhaiouList",
        name: "jinhaiouList",
        component: () => import("../views/unit/jinhaiouList"),
        meta: {
          title: "金海鸥"
        }
      },
      {
        path: "/small/smallList",
        name: "smallList",
        component: () => import("../views/small/list"),
        meta: {
          title: "小程序注册嘉宾"
        }
      },
      {
        path: "/fuwenben/fangyizhengze",
        name: "fangyizhengze",
        component: () => import("../views/fuwenben/fangyizhengze"),
        meta: {
          title: "防疫政策"
        }
      },
      {
        path: "/fuwenben/piandan",
        name: "piandan",
        component: () => import("../views/fuwenben/piandan"),
        meta: {
          title: "片单"
        }
      },
      {
        path: "/fuwenben/gerenchengnuo",
        name: "gerenchengnuo",
        component: () => import("../views/fuwenben/gerenchengnuo"),
        meta: {
          title: "个人承诺书"
        }
      },
      {
        path: "/fuwenben/changdijieshao",
        name: "changdijieshao",
        component: () => import("../views/fuwenben/changdijieshao"),
        meta: {
          title: "场地介绍"
        }
      },
      {
        path: "/fuwenben/canhuixuzhi",
        name: "canhuixuzhi",
        component: () => import("../views/fuwenben/canhuixuzhi"),
        meta: {
          title: "参会须知"
        }
      },
      {
        path: "/result/resultfell",
        name: "resultfell",
        component: () => import("../views/result/resultfell"),
        meta: {
          title: "拒绝参会"
        }
      },
      {
        path: "/guanliyuanqx/quanbudaitijiao",
        name: "quanbudaitijiao",
        component: () => import("../views/guanliyuanqx/quanbudaitijiao"),
        meta: {
          title: "全部嘉宾待提交"
        }
      },
      {
        path: "/icon",
        name: "icon",
        component: () => import("../views/icon"),
        meta: {
          title: "小程序图标管理"
        }
      },
      {
        path: "/zhanshang",
        name: "zhanshang",
        component: () => import("../views/zhanlanzhanshang"),
        meta: {
          title: "展览展映"
        }
      },
      {
        path: "/yingxiangku",
        name: "yingxiangku",
        component: () => import("../views/imagelibrary/imagelibrary.vue"),
        meta: {
          title: "影像管理"
        }
      },
      {
        path: "/yuyuecanhui",
        name: "yuyuecanhui",
        component: () => import("../views/yuyuecanhui/list"),
        meta: {
          title: "预约参会表"
        }
      },
      {
        path: "/yingxiangku/list",
        name: "yingxiangkulist",
        component: () => import("../views/yingxiangku/list"),
        meta: {
          title: "影像库列表"
        }
      }
    ]
  }
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};

NProgress.configure({ showSpinner: false });

// 前置导航守卫
router.beforeEach((to, from, next) => {
  // 如果非登录component 则进行token 验证
  if (to.name != "login") {
    console.log(to.name);
    let abc = localStorage.getItem("ref");

    if (abc) {
      try {
        let bcd = JSON.parse(localStorage.getItem("ref"));
        setWaterMark(`${bcd.uname},内部系统 禁止截屏`);
      } catch (error) {
        setWaterMark(`内部系统 禁止截屏`);
        console.log(error);
      }
    }
    let token = getToken();
    if (util.isEmpty(token)) {
      router.replace("/login");
      return;
    }
  } else {
    setWaterMark("");
  }

  NProgress.start();
  next();
});

// 后置导航守卫
router.afterEach((to, from) => {
  document.title = to.meta.title;
  NProgress.done();
});

export default router;
