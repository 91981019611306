import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Plugin from "v-fit-columns";
Vue.use(Plugin);
import "@/assets/css/normalize.css";
//图片放大组件
import VueDirectiveImagePreviewer from "vue-directive-image-previewer";
import "vue-directive-image-previewer/dist/assets/style.css";
Vue.use(VueDirectiveImagePreviewer);

//导入fontawesome
// import "@/assets/css/fontawesome-free-5.15.1-web/css/all.css";
// 引入svg 图标
import "@/common/icons";
// 引入ElementUI
import ElementUI from "element-ui";
import "@/common/styles/element-variables.scss";

// Vue.prototype.$baseUrl = "http://192.168.2.48:8095";
// Vue.prototype.$cmsBaseUrl = "http://192.168.2.48:8095";

//正式库
// Vue.prototype.$baseUrl = "https://ybh2022.gengduoke.com";
// Vue.prototype.$cmsBaseUrl = "https://ybh2022.gengduoke.com";

//测试库
// Vue.prototype.$baseUrl = "https://ybh2023.laidedui.com";
// Vue.prototype.$cmsBaseUrl = "https://ybh2023.laidedui.com";

//正式库
Vue.prototype.$baseUrl = "https://ybh2022.gengduoke.com";
Vue.prototype.$cmsBaseUrl = "https://ybh2022.gengduoke.com";

// Vue.prototype.$baseUrl = "https://ybh2022.gengduoke.com";
// Vue.prototype.$cmsBaseUrl = "https://ybh2022.gengduoke.com";
Vue.prototype.$sadsdasBaseUrl = "https://guanwang.gengduoke.com";

//测试版本挂网
// Vue.prototype.$phoneUrl = "https://ybhgw.laidedui.com";
/******************* */
//https://ybh2023.laidedui.com/adminapi/测试域名
//*********************** */
//https://guanwang.gengduoke.com 正式官网
//******************* */
//https://ybh2022.gengduoke.com   正式pc域名
/*************** */
// Vue.prototype.$baseUrl = "https://ybh.startinfo.cn";
// Vue.prototype.$cmsBaseUrl = "https://ybh.startinfo.cn";
// Vue.prototype.$baseUrl = "http://xu.gengduoke.com";
// Vue.prototype.$cmsBaseUrl = "http://xu.gengduoke.com";
// Vue.prototype.$sadsdasBaseUrl = "https://www.qdysblh.com";

Vue.prototype.$adminApiUrl = Vue.prototype.$baseUrl + "/adminapi";

Vue.use(ElementUI);
//引入 echarts
import echarts from "echarts";
//注册组件
Vue.prototype.$echarts = echarts;

import VueCanvasPoster from "vue-canvas-poster";
Vue.use(VueCanvasPoster);
import { VueJsonp } from "vue-jsonp";
Vue.use(VueJsonp);

import VueUeditorWrap from "vue-ueditor-wrap";
Vue.component("vue-ueditor-wrap", VueUeditorWrap);

Vue.config.productionTip = false;
// Vue.http.options.credentials = true

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
