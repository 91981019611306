<template>
  <div id="app">
    <router-view />
    <div class="navbar" ref="container"></div>
  </div>
</template>
<script>
// import warterMark from "./canvs/waterMars";
import { removeWatermark, setWaterMark } from "./canvs/waterMars";

export default {
  mounted() {
    const abc = localStorage.getItem("ref");
    console.log(abc, 222222222222);
    // setWaterMark("", "青岛影视周");
  },
  destroyed() {
    removeWatermark();
  },
};
</script>
<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
}

body {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
}

.svg-container {
  padding: 0px;
  vertical-align: middle;
  display: inline-block;
}
</style>
