import Cookies from 'js-cookie'
import { Loading } from 'element-ui';
import {getToken} from "@/common/utils/auth";

export default {

  deleteHtml(val) {
    if (this.isEmpty(val)){
      return "";
    }
    let str = val.replace(/<\/?[^>]*>/g, ''); //去除HTML Tag
    str = str.replace(/[|]*\n/, '') //去除行尾空格
    str = str.replace(/&npsp;/ig, ''); //去掉npsp
    return str;
  },
  fmtDate(d, fmt){ // yyyy-MM-dd hh:mm:ss
    if(!fmt){
      fmt = "yyyy-MM-dd hh:mm:ss";
    }
    var o = {
      "M+" : d.getMonth()+1,                 //月份
      "d+" : d.getDate(),                    //日
      "h+" : d.getHours(),                   //小时
      "m+" : d.getMinutes(),                 //分
      "s+" : d.getSeconds(),                 //秒
      "q+" : Math.floor((d.getMonth()+3)/3), //季度
      "S"  : d.getMilliseconds()             //毫秒
    };
    if(/(y+)/.test(fmt)) {
      fmt=fmt.replace(RegExp.$1, (d.getFullYear()+"").substr(4 - RegExp.$1.length));
    }
    for(var k in o) {
      if(new RegExp("("+ k +")").test(fmt)){
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
      }
    }
    return fmt;
  },
  unique(arr){
   //Set数据结构，它类似于数组，其成员的值都是唯一的 ES6
   return Array.from(new Set(arr)); // 利用Array.from将Set结构转换成数组
  },
  promiseStop(){
    return new Promise(()=>{})
  },
  promiseToCatch(){
    return new Promise((resolve, reject)=>{
      reject()
    })
  },
  isLogin(){
    var token = getToken();
    if(this.isEmpty(token)){
      return false;
    }
    return true;
  },
  isEmpty(val){
    if(val == null || val == undefined || val == 'undefined' || val == 'null' || val == 'nil' || val.length == 0 || (typeof val == "string" && val.trim().length == 0)) {
      return true;
    }
    return false;
  },
  isNotEmpty(val) {
    return !this.isEmpty(val)
  },
  setCookie(k,v){
    Cookies.set(k,v)
  },
  getCookie(k){
    return Cookies.get(k)
  },
  removeCookie(k){
    return Cookies.remove(k)
  },
  showLoading(str) {
    if(this.isEmpty(str)) {
      str = "加载中"
    }
    return Loading.service({
      fullscreen: true,
      lock: true,
      text: str,
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
      zIndex: 99999,
      customClass: 'el-loading-custom-class',
    });
  }
}
