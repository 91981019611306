import util from "./util"
// 手机号验证
export function isvalidPhone(str) {
  const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
  return reg.test(str)
}

export function validPhone(rule, value,callback) {
  if(util.isEmpty(value)){
    callback();
    return;
  }
  if(!isvalidPhone(value)){
    callback(new Error(rule.message))
  }else{
      callback()
  }
}

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}
