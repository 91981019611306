import Cookies from 'js-cookie'
import util from './util.js'

const TokenKey = 'X-Token'

export function getToken() {
  let token = Cookies.get(TokenKey)
  if(util.isEmpty(token)){
    return '';
  }
  return token;
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
